/** General Rules */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 230px; /* height of sticky header */
}

html body {
  color: var(--do-color-10);
  font-family: mr-eaves-modern, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: mrs-eaves, "Georgia Regular", "Baskerville Regular", serif;
  font-style: normal;
}

body {
  position: relative;
  overflow-y: auto;
}

nav li {
  padding-left: 0.3rem !important;
}

/** END General Rules */

/** Header Rules */
#main_header {
  background-color: #FFFFFF;
}

#main_header div#hdr_bkgrnd,
div#menu_bkgrnd {
  background-color: rgba(0, 54, 28, .2);
}

#main_header .logged-in {
  cursor: pointer;
}

#main_header .nav-pills .nav-link.active,
#main_header .nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: var(--do-color-3);
}

#main_header .nav-link:hover,
#main_header .nav-link:focus,
#main_header .nav-link.active:hover,
#main_header .nav-link.active:focus {
  color: #FFFFFF;
  background-color: var(--do-color-4);
}

/** END Header Rules */

/** Content Rules */
#main_content {
  background-color: #FFFFFF;
}

div#main_content div.platform-wrapper.w-100.row {
  margin: 0;
}

div#main_content div.reflib-wrapper div {
  padding: 24px;
  max-width: 100%;
}

div#main_content div.reflib-wrapper * {
  padding: 12px;
  border: #B0413E 1px solid;
  border-radius: 3px;
}

div#main_content div.reflib-wrapper .data-elements {
  border-top: none;
  border-radius: 0;
}

div#main_content div.reflib-wrapper .data-elements .data-name {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

div#main_content div.reflib-wrapper .data-elements .data-hint {
  border-radius: 0;
  border-left: none;
}

div#main_content div.reflib-wrapper .data-elements .data-doi {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none;
}

div#main_content div.reflib-wrapper .data-primary-text {
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

ul.pagination {
  margin: 16px;
}

ul.pagination li a {
  line-height: 0.75;
}

h3 select.search-reflib {
  font-size: 20px;
}

h3 select.search-reflib {
  font-size: 1.25rem;
}

h3 span.select2 {
  display: none;
}

.reflib-wrapper div {
  word-break: break-all;
}

/** END Content Rules */

/** Footer Rules */
#main_footer {
  background-color: #FFFFFF;
}

#main_footer .poweredby-wrapper {
  letter-spacing: .25rem;
}

#main_footer .poweredby-wrapper img.dairy-api-logo {
  max-height: 35px;
}

#main_footer .email-link {
  font-style: italic;
  color: var(--do-color-10);
  font-size: 1.25rem;
}

#main_footer .email-link:hover {
  text-decoration: none;
  color: var(--do-color-10);
}

#main_footer img.facticity-logo {
  max-height: 75px;
}

/** END Footer Rules */

/** Home Page Rules */
body#home div.main-content h2 {
  text-align: center;
}

body#home div.main-content p {
  text-align: justify;
}

.platform-wrapper .col-md-4 {
  color: #FFFFFF;
}

.gatekeeper {
  background-color: var(--do-color-8);
}

.connector {
  background-color: var(--do-color-6);
}

.automator {
  background-color: var(--do-color-4);
}

/** END Home Page Rules */

/** Custom Rules */
.text-justify {
  text-align: justify;
}

.w-1200 {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

:root {
  --do-color-1: rgb(0, 54, 28);
  --do-color-2: rgb(0, 104, 56);
  --do-color-3: rgb(9, 24, 160);
  --do-color-4: rgb(0, 174, 239);
  --do-color-5: rgb(109, 207, 246);
  --do-color-6: rgb(17, 102, 53);
  --do-color-7: rgb(0, 175, 76);
  --do-color-8: rgb(156, 28, 34);
  --do-color-9: rgb(237, 32, 36);
  --do-color-10: rgb(35, 31, 32);
}

/** END Custom Rules */


// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
